<template>
  <div class="">
    <div class="w ww">
      <div class="pc_fl jishu_top_lf">
        <!-- 产品筛选 -->
        <div class="product-shaixuan">
          <button class="product-show-button" @click="productShowClick">
            产品筛选
          </button>
        </div>
        <el-tree
          class="el-tree-data"
          :data="treeData"
          show-checkbox
          @check-change="handleCheckChange"
          ref="tree"
          node-key="id"
          :default-expanded-keys="isOpenKeys"
        ></el-tree>
        <el-tree
          :data="treeData"
          v-if="productShow"
          show-checkbox
          @check-change="handleCheckChange"
          ref="tree"
          node-key="id"
          :default-checked-keys="isSelectKeys"
          :default-expanded-keys="isSelectKeys"
        ></el-tree>
      </div>
      <div class="pc_fr banner">
        <div class="swiper-container">
          <div class="pc_fr kt_list_rt">
            <div class="jishu_con_t">
              <div class="w jishu_con_t_1">
                <span>{{ $t("total") }} {{ totalCount }} </span
                ><a @click="prevPage($event, currentPage)">&lt;</a
                ><i>{{ currentPage }}</i
                >/{{ totalPage
                }}<a @click="nextPage($event, currentPage)">&gt;</a>
              </div>
              <div class="clear"></div>
            </div>
            <ul class="zx_c">
              <li class="thisclass">
                <dl>
                  <dd v-for="(v, i) in showTime" :key="v.owid">
                    <a
                      @click.stop="goDetail(v.owid, v)"
                      @contextmenu="newWindowOpen($event, v.owid, v.exp2)"
                    >
                      <div class="kt_list_img">
                        <img class="kt_pro_img" :src="v.productPic" />
                      </div>
                      <div class="kt_list_txt">
                        <h3>{{ v.productName || "" }}</h3>
                        <div class="kt_list_txt_1">
                          <p>{{ $t("no") }}：{{ v.productCode || "-" }}</p>
                        </div>
                        <span
                          >{{ v.specsForeign == 1 ? "$" : "￥"
                          }}{{ v.productPrice || "-" }}</span
                        >
                        <i v-if="Number(v.specsPromotionprice) == 0">
                          {{ $t("Inquiry") }}
                        </i>
                        <i v-else
                        >{{ v.specsForeign == 1 ? "$" : "￥"
                          }}{{  v.specsPromotionprice   || "-"   }}</i
                        >
                      </div>
                      <div class="kt_list_txt_4">
                        <div
                          class="fl kt_list_txt_6"
                          @click.stop="collect(v.owid, v.isCollection, i)"
                        >
                          <a>
                            <img
                              :src="
                                v.isCollection == 1
                                  ? require('@/assets/img/29-1.png')
                                  : require('@/assets/img/29.png')
                              "
                            />
                            {{
                              v.isCollection == 1
                                ? $t("collected")
                                : $t("collect")
                            }}
                          </a>
                        </div>
                        <div class="fl width40 kt_list_txt_7">
                          <a
                            @click.stop="
                              addCardItem({
                                productRefOwid: v.owid,
                                specsName: v.specsName,
                                productPic: v.productPic,
                                itemPrice: v.specsPromotionprice,
                                categoryName: v.categoryName,
                                categoryRefOwid: v.categoryRefOwid,
                                specsRefOwid: v.specsRefOwid,
                                itemQuanity: 1,
                              })
                            "
                          >
                            <img src="@/assets/img/01.png" /><span
                              class="card-txt"
                              >{{ $t("addCard") }}</span
                            ></a
                          >
                        </div>
                        <div class="clear"></div>
                      </div>
                    </a>
                  </dd>
                  <div class="clear"></div>
                </dl>
                <Pagination
                  :totalPage="totalPage"
                  :currentPage="currentPage"
                  @pageFun="goPageSrh"
                  class="pagination"
                />
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="clear"></div>
    </div>
  </div>
</template>

<script>
import { CODE, isHasLogin, showLayer, hlLeftMenu } from "@/utils/com";
import {
  getNorms,
  getAdv,
  rdmPro,
  handleObj2Arr,
  addCart,
  filterTreeList,
  getNewProduct,
} from "./model";
import { mapState, mapActions, mapMutations } from "vuex";
import NullTip from "@/components/component/NullTip.vue";
import Pagination from "@/components/component/Pagination.vue";
import { IMGURL } from "@/utils/constant";
let pageSize = 5;

export default {
  name: "Anti",
  data() {
    return {
      pictureUrl: "",
      swiperList: [],
      arrId: this.$route.query?.id || "",
      arrList: this.$route.query?.params || "",
      proId:
        this.$route.query?.idThi ||
        this.$route.query?.idSec ||
        this.$route.query?.id ||
        "",
      optionArr: {},
      //快捷搜索
      tagSrh: {
        time: 2, //降序
        view: "",
        prize: "",
      },
      //被选中option
      selOptObj: {},
      //推荐产品
      rmdProArr: [],
      pageN: 1,
      pageSize: 6,
      rmdTotalPage: 0,

      thirdVal: "", //左边的三级搜索
      searchKey: this.$route.query?.searchVal || "",
      cateOwid: "",
      treeData: [],
      attrList: [],
      attrListArr: [],
      isSelectKeys: [], //默认选中的id
      treeShow: "",
      isOpenKeys: [],
      str: "",
      pageInfo: "",
      totalPage: 0,
      currentPage: 0,
      totalCount: 0,
      productShow: false,
    };
  },
  computed: {
    ...mapState("common", ["selectOpts", "selTwo", "selTwo", "leftMenu"]),
    // ...mapState("product", [
    //   "productList",
    //   "totalCount",
    //   "totalPage",
    //   "currentPage",
    // ]),
    getMenuLeft() {
      //获取页面左侧菜单
      return hlLeftMenu(this.leftMenu);
    },
    showTime() {
      let showTime = "";
      if (this.treeShow === true) {
        showTime = this.attrListArr;
        this.totalPage = this.pageInfo.totalPage;
        this.currentPage = this.pageInfo.currentPage;
        this.totalCount = this.pageInfo.totalCount;
      } else {
        showTime = this.$store.state.product.productList;
        this.totalPage = this.$store.state.product.totalPage;
        this.currentPage = this.$store.state.product.currentPage;
        this.totalCount = this.$store.state.product.totalCount;
      }
      return showTime;
    },
  },

  async created() {
    this.pictureUrl = IMGURL;
    this.cateOwid = this.$route.query.id;
    getNorms({ cateOwid: this.arrId }).then((res) => {
      this.optionArr = res;
    });
    getAdv({ lx: CODE.KANGTI }).then((res) => {
      this.swiperList = res;
    });
    this.getRmd();
    this.search();
    filterTreeList({ cateOwid: this.cateOwid }).then((res) => {
      this.treeData = res;
      let treeKey = [];
      for (let x = 0; x < this.treeData.length; x++) {
        treeKey.push(this.treeData[x]);
        if (this.treeData[x].children) {
          for (let y = 0; y < this.treeData[x].children.length; y++) {
            treeKey.push(this.treeData[x].children[y]);
            if (this.treeData[x].children[y].children) {
              for (
                let z = 0;
                z < this.treeData[x].children[y].children.length;
                z++
              ) {
                treeKey.push(this.treeData[x].children[y].children[z]);
              }
            }
          }
        }
      }
      //  筛选isShow为1 的对象获取相对应的id
      this.treeShow = treeKey.filter((item) => {
        return item.isShow === 1;
      });
      this.treeShow.forEach((item) => {
        this.isOpenKeys.push(item.id);
      });
    });
  },

  mounted() {
    if (this.arrList.length > 0) {
      this.defaultSelect([...JSON.parse(this.arrList)]);
    }
  },

  methods: {
    ...mapActions("common", ["getSelOpt"]),
    ...mapActions("product", ["getProduct", "takeClt", "cancelClct"]),
    ...mapMutations("product", ["setProduct"]),

    //头部三级搜索
    thirdSrh(e) {
      let val = e.target.dataset.id;
      this.thirdVal == val ? (this.thirdVal = "") : (this.thirdVal = val);
      this.search();
    },

    //tag 快捷搜索
    setTagSrh(type, e) {
      let { time, view } = this.tagSrh;
      if (type == "time") {
        time ? (this.tagSrh.time = "") : (this.tagSrh.time = 2);
      }
      if (type == "view") {
        view ? (this.tagSrh.view = "") : (this.tagSrh.view = 2);
      }
      if (type == "prize") {
        let d = e.target.dataset.id;
        this.tagSrh.prize = d || "";
      }
      this.search();
    },

    //搜索产品
    search(pageNo = 1) {
      let { time, view, prize } = this.tagSrh;
      this.getProduct({
        categoryRefOwid: this.thirdVal || this.proId,
        key: this.searchKey,
        fbsj: time,
        xl: view,
        jg: prize,
        pageNo,
        attrList: handleObj2Arr(this.selOptObj),
      });
    },

    //分页搜索
    goPageSrh(pageNo) {
      if (this.treeShow) {
        this.pageN = pageNo;
        this.handleCheckChange();
      } else {
        this.search(pageNo);
      }
    },

    //选中塞选条件
    selectAttr(e) {
      let x = e.target.dataset.x;
      let y = e.target.dataset.y;
      if (this.optionArr[x].values) {
        let isSelect = this.optionArr[x].values[y].isSelect;
        let code = this.optionArr[x].code;
        let value = this.optionArr[x].values[y].val;
        let beforeVal = this.selOptObj[code];
        if (isSelect) {
          if (beforeVal.indexOf(value + ",") > -1) {
            this.selOptObj[code] = beforeVal.replace(value + ",", "");
          } else {
            this.selOptObj[code] = beforeVal.replace(value, "");
          }
        } else {
          this.selOptObj[code] = beforeVal ? beforeVal + "," + value : value;
        }
        this.optionArr[x].values[y].isSelect = !isSelect;
        this.search();
      }
    },

    //产品推荐数据
    async getRmd() {
      let res  = await rdmPro({
        pageSize,
        pageNo: this.pageNo++,
        type: 5,
      });
      this.rmdProArr = res?.records||[];
      this.rmdTotalPage = res?.totalPage;
      if (res?.totalPage == res?.currentIndex) {
        this.pageNo = 1;
      }
    },

    //精品推荐下一页
    goNextRmd() {
      this.getRmd();
    },

    //详情
    goDetail(owid, item) {
      this.$router.push({
        path: "/productDetails",
        query: { proId: owid, exp2: item.exp2 },
      });
    },

    //收藏
    async collect(id, isCollection, index) {
      if (isCollection == 1) {
        //已經收藏取消收藏
        let result = await this.cancelClct({ collectList: id, index });
        result && showLayer("success", this.$t("calCollect"));
        this.handleCheckChange();
      } else {
        //收藏
        let result = await this.takeClt({ collectList: id, index });
        result && showLayer("success", this.$t("clectSuccess"));
        this.handleCheckChange();
      }
    },
    // 加入购物车
    addCardItem(params) {
      addCart(params).then((res) => {
        if (res === "添加购物车成功") {
          showLayer("success", "添加成功，在购物车等您");
        }
      });
    },
    //默认选中
    defaultSelect(tree) {
      tree.forEach((item) => {
        this.isSelectKeys.push(item.attrCode);
        let treeObject = {
          attrCode: item.attrCode || this.arrList.attrCode,
          attrValue: item.attrValue || this.arrList.attrValue,
          fid: item.fid || this.arrList.fid,
        };
        this.attrList.push(treeObject);
      });
      this.requestData();
    },

    // 树形控件
    handleCheckChange(data, checked, indeterminate) {
      let tree = this.$refs.tree.getCheckedNodes();
      console.log(tree, "====");
      tree.forEach((item) => {
        let treeObject = {
          attrCode: item.id || this.arrList.attrCode,
          attrValue: item.label || this.arrList.attrValue,
          fid: item.fid || this.arrList.fid,
        };
        this.attrList.push(treeObject);
      });
      this.requestData();
    },
    // 发送请求
    requestData() {
      if (this.attrList.length !== 0) {
        getNewProduct({
          attrList: this.attrList,
          pageNo: this.pageN,
          pageSize: this.pageSize,
        }).then((res) => {
          this.attrListArr = res.records;
          this.pageInfo = res;
          this.treeShow = true;
          if (this.attrListArr) {
            this.attrListArr.forEach((item) => {
              item.productPic = this.pictureUrl + item.productPic;
            });
          }
        });
      } else {
        this.treeShow = false;
      }
      this.attrList = [];
    },
    newWindowOpen(event, owid, exp2) {
      event.path[2].href = "";
      event.path[2].href =
        event.path[2].href +
        "/#/productdetails/?proId=" +
        owid +
        "&" +
        "exp2=" +
        exp2;
    },
    // 上一页
    prevPage(e, currentPage) {
      if (this.treeShow === true) {
        this.pageN = currentPage - 1;
        this.handleCheckChange();
      } else {
        let pageNo = currentPage - 1;
        this.search(pageNo);
      }
    },
    // 下一页
    nextPage(e, currentPage) {
      if (this.treeShow === true) {
        this.pageN = currentPage + 1;
        this.handleCheckChange();
      } else {
        let pageNo = currentPage + 1;
        this.search(pageNo);
      }
    },
    productShowClick() {
      this.productShow = !this.productShow;
    },
  },

  components: {
    NullTip,
    Pagination,
  },
};
</script>
<style scoped>
.pagination {
  margin-bottom: 0.1rem;
}
</style>
